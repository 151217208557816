import { Radio } from '@ui/forms/radio-group/radio';
import { UpsellBillingCycle } from './find-best-price';
import { Trans } from '@ui/i18n/trans';
import { RadioGroup, RadioGroupProps } from '@ui/forms/radio-group/radio-group';

import { Product } from '../product';

interface BillingCycleRadioProps extends Omit<RadioGroupProps, 'children'> {
  selectedCycle: UpsellBillingCycle;
  onChange: (value: UpsellBillingCycle) => void;
  products?: Product[];
}

export function BillingCycleRadio({
  selectedCycle,
  onChange,
  products,
  ...radioGroupProps
}: BillingCycleRadioProps) {
  return (
    <RadioGroup {...radioGroupProps}>
      {['ETB', 'USD'].map(currency => (
        <Radio
          key={currency}
          value={currency}
          checked={selectedCycle === currency}
          onChange={e => {
            console.log(e.target.value);
            onChange(e.target.value as UpsellBillingCycle);
          }}
        >
          <Trans message={currency === 'ETB' ? 'Ethiopian Birr (ETB)' : 'US Dollar (USD)'} />
        </Radio>
      ))}
    </RadioGroup>
  );
}