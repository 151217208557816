import { Play, Pause } from 'lucide-react';

interface Track {
  title: string;
  artist: string;
}

interface MobilePlayerProps {
  isPlaying: boolean;
  currentTrack: Track;
  albumArt: string;
  togglePlayPause: () => void;
}

interface NowPlayingProps {
  track: Track;
  albumArt: string;
  isPlaying: boolean;
}

const MobilePlayer: React.FC<MobilePlayerProps> = ({
  isPlaying,
  currentTrack,
  albumArt,
  togglePlayPause,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '80vh',
      justifyContent: 'center',
      color: 'white',
      padding: '16px',
      overflowY: 'auto',
    }}
  >
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <NowPlaying track={currentTrack} albumArt={albumArt} isPlaying={isPlaying} />
    </div>

    <div style={{ flexShrink: 0, padding: '16px 0', display: 'flex', justifyContent: 'center' }}>
      <button
        style={{
          backgroundColor: 'white',
          color: 'black',
          borderRadius: '50%',
          padding: '16px',
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          boxShadow: isPlaying ? '0px 0px 20px rgba(255,255,255,0.3)' : 'none',
          transition: 'all 0.3s ease-in-out',
        }}
        onClick={togglePlayPause}
      >
        {isPlaying ? (
          <Pause size={32} style={{ color: 'black', strokeWidth: 1.5 }} />
        ) : (
          <Play size={32} style={{ color: 'black', strokeWidth: 1.5 }} />
        )}
      </button>
    </div>
  </div>
);

const NowPlaying: React.FC<NowPlayingProps> = ({ track, albumArt, isPlaying }) => (
  <div
    style={{
      width: '100%',
      maxWidth: '320px',
      backgroundColor: '#2a2a2a',
      borderRadius: '12px',
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)',
    }}
  >
    <div
      style={{
        width: '100%',
        paddingBottom: '100%',
        position: 'relative',
        backgroundColor: '#333',
        marginBottom: '16px',
        borderRadius: '12px',
        overflow: 'hidden',
      }}
    >
      <img
        src={albumArt}
        alt="Album cover"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </div>
    <h2
      style={{
        fontSize: '1.25rem',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {track.title}
    </h2>
    <p style={{ fontSize: '1rem', color: '#bbb', marginBottom: '16px' }}>{track.artist}</p>
    <div style={{ marginTop: '8px', display: 'flex', justifyContent: 'center' }}>
      <p style={{ fontSize: '1.125rem', fontWeight: 'bold' }}>
        {isPlaying ? 'Playing Now' : 'Press Play to Listen'}
      </p>
    </div>
  </div>
);

export default MobilePlayer;
