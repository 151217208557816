import { Price } from '../price';

export enum UpsellBillingCycle {
  ETB = 'ETB',
  USD = 'USD',
}

export function findBestPrice(
  token: UpsellBillingCycle,
  prices: Price[]
): Price | undefined {
  return token === UpsellBillingCycle.ETB
    ? findMonthlyPrice(prices)
    : findYearlyPrice(prices) || prices[0];
}

function findYearlyPrice(prices: Price[]) {
  return prices.find(price => price.currency === UpsellBillingCycle.USD);
}

function findMonthlyPrice(prices: Price[]) {
  return prices.find(price => price.currency === UpsellBillingCycle.ETB);
}