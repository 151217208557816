import React, { useEffect, useState,memo, useMemo } from 'react';


import VolumeControl from '@app/web-player/radio/components/MobilePlayer';
import  MobilePlayer  from '@app/web-player/radio/components/MobilePlayer';

import useAudioPlayer from '@app/web-player/radio/requests/useAudioPlayer';
import {Navbar} from '@common/ui/navigation/navbar/navbar';
import {Footer} from '@common/ui/footer/footer';

const MemoizedVolumeControl = memo(VolumeControl);

export function RadioPage() {


  const { 
    isPlaying, 
    currentTrack, 
    albumArt,
    volume,
    togglePlayPause,
    load
  } = useAudioPlayer();

  useEffect(() => {
    load();
  }, [load]);


  return (

    <div className="flex flex-col min-h-screen bg">
      <Navbar
        menuPosition="custom-page-navbar"
        className="flex-shrink-0 sticky top-0"
      />
        <MobilePlayer
        isPlaying={isPlaying}
        currentTrack={currentTrack}
        albumArt={albumArt}
        togglePlayPause={togglePlayPause}
      />
      <Footer className="mx-14 md:mx-40" />
    </div>
  
  );
}


